import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { MailIcon, PhoneIcon, SunIcon } from "@heroicons/react/solid";
import ContactForm from "../components/form/ContactForm";
import { StaticImage } from "gatsby-plugin-image";
import GMap from "../components/Map";
import { default as Zahrady } from "../images/zahrady.svg";

const StavebniPlanPage = ({ location }) => {
    // Move this to it's own component consuming context.

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Stavební plán | Domy Trio</title>
            </Helmet>
            <Layout location={location}>
                <main>
                    <div className="mx-auto w-full   relative z-30 ">
                        <div className="mx-auto max-w-6xl w-full p-8 lg:px-14 z-auto  backdrop-filter  bg-white bg-opacity-90 relative shadow-md border border-gray-200 ">
                            <svg className="absolute w-60 h-60 text-yellow-400 top-0 right-0 ">
                                <polygon
                                    points="0,0 300,0 300,300"
                                    class="triangle"
                                    fill="currentColor"
                                />
                                <SunIcon
                                    width="50"
                                    x="160"
                                    y="-70"
                                    fill="#ffffff"
                                />
                            </svg>
                            <div className="relative">
                                <h1 className="text-xl lg:text-3xl font-merri mb-10 border-b border-yellow-400 pb-3">
                                    Stavební plán
                                </h1>
                                <div className="prose prose-sm">
                                    <p>Stavba byla zkolaudována v 10/2022</p>
                                </div>
                                <div>
                                    <div className="my-10">
                                        <GMap zoom={15} />
                                    </div>

                                    <img
                                        src={Zahrady}
                                        className="w-full border mb-5 font-normal"
                                    />

                                    <StaticImage
                                        src={"../images/stavebni-plan.jpg"}
                                        className="w-full border"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div></div>
            </Layout>
        </>
    );
};

export default StavebniPlanPage;
